import React from "react";

import "../Styles/App.css";

function AgendaComming() {
  return (
    <>
      <div
        className="agenda-cs-cont"
        style={{ backgroundColor: "white" }}
      ></div>
    </>
  );
}
export default AgendaComming;
